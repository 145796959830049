import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import moment from "moment"
import swal from "vue-sweetalert2"
import "sweetalert2/dist/sweetalert2.min.css"
import VueToastr2 from "vue-toastr-2"
import "vue-toastr-2/dist/vue-toastr-2.min.css"
import VueNumber from "vue-number-animation"
import VueClipboard from "vue-clipboard2"
import vSelect from "vue-select"
import { Datetime } from "vue-datetime"
import "vue-datetime/dist/vue-datetime.css"
import VueSocketIO from "vue-socket.io"
import CKEditor from "@ckeditor/ckeditor5-vue"
import ToggleButton from "vue-js-toggle-button"
import VueViewer from "v-viewer"
import "viewerjs/dist/viewer.css"

import {
    GET_SITE,
    APP_IS_MOBILE,
    USER_GET_INFO,
    APP_IS_MIMI_SIDEBAR,
    GET_TOOLS,
    STORE_SET_USER,
    GET_MENU_OUT_SITE
} from "./store/types"

Vue.use(
    new VueSocketIO({
        debug: false,
        connection: "https://realtime.fb-api.com"
    })
)

Vue.use(VueViewer)
Vue.use(CKEditor)
Vue.use(ToggleButton)
Vue.use(VueClipboard)
Vue.use(VueNumber)
window.toastr = require("toastr")
Vue.use(swal)
Vue.use(VueToastr2)
Vue.component("v-select", vSelect)
Vue.component("datetime", Datetime)
moment.locale("vi")
Vue.config.productionTip = false
Vue.config.devtools = true

Vue.filter("formatNumber", function(x) {
    return x
        ? Math.round(Number(x))
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : 0
})
Vue.filter("timeAgo", function(x) {
    let time = moment(x).fromNow()
    return time.includes("Invalid") ? x : time
})
new Vue({
    router,
    store,
    render: h => h(App),
    destroyed() {
        window.removeEventListener("resize", this.handleResize)
    },
    data: {
        show: true,
        window: {
            width: 0,
            height: 0
        },
        oldSize: 0
    },
    computed: {
        route() {
            return this.$route.path
        },
        beforePath() {
            return this.$store.state.app.beforePath
        }
    },
    watch: {
        route(value) {
            if (this.$store.state.user.api_token && value === "/login") {
                this.$router.push("/")
            }
        }
    },
    async created() {
        Vue.set(this.$store.state.app, "isMiniSidebar", false)
        Vue.set(this.$store.state.app, "isLoading", 0)
        await this.getSite()
        if (this.$store.state.user.api_token) {
            // console.log("init socket user: " + this.$store.state.user.user.id)
            this.$socket.emit("REALTIME_INIT", {
                user_id: this.$store.state.user.user.id,
                username: this.$store.state.user.user.username
            })
            this.$store.dispatch(GET_MENU_OUT_SITE)
            this.$store.dispatch(GET_TOOLS)
            this.$store.dispatch(USER_GET_INFO)
        }
        let soundNoti = new Audio(require("../public/assets/sound/notification.mp3"))
        this.sockets.subscribe("REALTIME_UPDATE_USER", data => {
            this.$store.commit(STORE_SET_USER, data)
        })
        this.sockets.subscribe("REALTIME_CREATE_USER_NOTIFICATION", data => {
            soundNoti.play()
            if (data.type === "handle_coin") {
                this.$swal.fire("Thành công", data.content, "success")
            } else this.$toastr.success(data.content, "Thông báo", { timeOut: 180000 })
        })
        this.oldSize = window.innerWidth
        window.addEventListener("resize", this.handleResize)
        if (window.innerWidth < 768) {
            this.$store.commit(APP_IS_MOBILE, true)
            document.body.classList.add("mini-sidebar")
        } else {
            this.$store.commit(APP_IS_MOBILE, false)
            document.body.classList.remove("mini-sidebar")
        }
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth
            this.window.height = window.innerHeight
            if (this.window.width < 768 && this.oldSize >= 768) {
                this.$store.commit(APP_IS_MOBILE, true)
                this.$store.commit(APP_IS_MIMI_SIDEBAR)
                // this.$store.commit('handleSidebar');
                this.oldSize = this.window.width
            }
            if (this.window.width > 768 && this.oldSize <= 768) {
                this.$store.commit(APP_IS_MOBILE, false)
                document.body.classList.remove("mini-sidebar")
                this.oldSize = this.window.width
            }
        },
        getSite: async function() {
            let siteHost = document.location.host
            if (siteHost.match(/192.168.1.100|localhost|192.168.1.188|192.168.1.108/)) {
                siteHost = "agency.anhnt0212.info"
                console.log(`Run with localhost test with domain ${siteHost}`)
            }
            let data = await this.$store.dispatch(GET_SITE, siteHost)
            if (data.site) {
                let isLogin = !!store.state.user.api_token
                let isLandingPage = !!store.state.site.site.landing_page
                if (!isLogin && !this.beforePath && isLandingPage) {
                    window.location.href = window.location.origin + "/landing-page.html"
                    store.commit("APP_BEFORE_PATH", this.route)
                }
            } else if (!data.site) {
                this.$store.commit("STORE_SET_TOKEN", null)
                this.$store.commit("STORE_SET_USER", null)
                if (data.message) {
                    this.$swal("Thông báo", data.message, "error")
                }
                this.$router.push("/api-key")
            }
        }
    }
}).$mount("#app")
